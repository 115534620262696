import TYPES from '@/types';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import EditCustomGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-current-plan-service';
import EditCustomGoalPlanInitialAmountService
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/application/services/edit-custom-goal-plan-initial-amount-service';

// Domain
import { InitialAmountEntity }
  from '@/modules/flagship/edit-plan-goals/edit-custom-goal-plan/domain/entities/initial-amount-entity';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class EditCustomGoalInitialAmountViewModel {
  @Inject(TYPES.EDIT_CUSTOM_GOAL_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditCustomGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_CUSTOM_GOAL_PLAN_INITIAL_AMOUNT_SERVICE)
  private readonly initial_amount_service!: EditCustomGoalPlanInitialAmountService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-custom-goal-plan.initial_amount';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  is_valid_form = false;

  current_amount = '';

  target_amount = '';

  there_is_more_savings = 'no';

  additional_amount = '';

  input_rules = {
    additional_amount: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  there_is_more_savings_options = [
    {
      label: this.translate('yes'),
      value: 'yes',
    },
    {
      label: this.translate('no'),
      value: 'no',
    },
  ];

  initialize = () => {
    this.setInitialValues();
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form;
  }

  get show_additional_amount() {
    return this.there_is_more_savings === 'yes';
  }

  get additional_amount_formatted() {
    return this.getAmountFormatted(this.parseCurrencyToNumber(this.additional_amount));
  }

  getAmountFormatted = (amount: number) => (currencyFormat(amount))

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  getInitialAmountInformation = () => (this.initial_amount_service.getInitialAmountInformation());

  getCurrentPlantInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  setInitialAmountInformation = (initial_amount: InitialAmountEntity) => {
    this.initial_amount_service.setInitialAmountInformation(initial_amount);
  }

  setInitialValues = () => {
    const investor_goal = this.getCurrentPlantInformation();
    const initial_amount = this.getInitialAmountInformation();
    this.target_amount = investor_goal.target_amount;
    this.current_amount = investor_goal.current_amount;
    if (initial_amount.additional_amount > 0) {
      this.additional_amount = String(initial_amount.additional_amount);
      this.there_is_more_savings = initial_amount.there_is_more_savings;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const initial_amount_entity: InitialAmountEntity = {
      there_is_more_savings: this.there_is_more_savings,
      additional_amount: (this.additional_amount) ? this
        .parseCurrencyToNumber(this.additional_amount) : 0,
    };
    this.setInitialAmountInformation(initial_amount_entity);
    this.view.$emit('nextStep');
  }
}
